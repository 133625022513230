@import '../../globalSassVariables.scss';

.textcard {
    display: flex;
    justify-content: space-between;
  }

.textcard .text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.textcard .textcard-image {
    align-self: center;
    margin: auto;
  }


@media (max-width: 768px) {
  .textcard {
      flex-direction: column;
    }
  
  .textcard .textcard-image {
    width: 50%;
  }
}