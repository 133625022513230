@import '../../globalSassVariables.scss';

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.header {
    background-color: $secondaryBackground;
    max-width: 90%;
    padding: 15px 20px;
    margin: 12px auto;
    border-radius: 10px;
    box-shadow: 0px 25px 30px rgba(48, 63, 78, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.header .text-logo {
    font-size: 25px;
    color: $textColor;
    font-family: $logoFont;
    text-decoration: none;
  }

.header .badge {
    margin-left: 10px;
    font-size: medium;
    vertical-align: top;
    color: #4884f4;
  }

.header .nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

.header .nav-item {
    padding: 5px 20px 5px 5px;
  }

.header .nav-item a {
    text-decoration: none;
    color: $textColor;
    border-radius: 6px;
    padding: 5px 15px 5px 15px;
    transition: color 2s ease;
    transition: background-color 0.5s ease;
  }

.header .nav-item a:hover {
    text-decoration: underline;
    color: #5590ff;
    background-color: aliceblue;
    transition: color 0.7s ease;
    transition: background-color 0.7s ease;
  }

@media (max-width: 768px) {
    nav {
        display: none;
      }
    
    .text-logo {
        align-self: center;
        margin: auto;
      }
  }