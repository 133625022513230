.hability-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-shrink: 0;
  }

.hability-container .text {
    margin: 10px;
    overflow-wrap: break-word;
    flex: 1;
  }