.container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 100%;
    max-width: 100%;
    margin: 40px;
  }

.col-no-center {
    justify-content: flex-start;
    align-items: flex-start;
  }

@media (min-width: 768px) {
    .row { flex-wrap: nowrap; }
    .col-1 { flex: 0 0 8.33%; max-width: 8.33%; }
    .col-2 { flex: 0 0 16.66%; max-width: 16.66%; }
    .col-3 { flex: 0 0 25%; max-width: 25%; }
    .col-4 { flex: 0 0 33.33%; max-width: 33.33%; }
    .col-5 { flex: 0 0 41.66%; max-width: 41.66%; }
    .col-6 { flex: 0 0 50%; max-width: 50%; }
    .col-7 { flex: 0 0 58.33%; max-width: 58.33%; }
    .col-8 { flex: 0 0 66.66%; max-width: 66.66%; }
    .col-9 { flex: 0 0 75%; max-width: 75%; }
    .col-10 { flex: 0 0 83.33%; max-width: 83.33%; }
    .col-11 { flex: 0 0 91.66%; max-width: 91.66%; }
    .col-12 { flex: 0 0 100%; max-width: 100%; }
  }