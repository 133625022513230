@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

/* BACKGROUND COLORS */
$primaryBackground: #242c36;
$secondaryBackground: #182027;

/* FONT COLORS */
$textColor: #D0D6E1;
$textGray: #9e9e9e;

/* FONT FAMILIES */
$logoFont: 'Rubik', sans-serif;
$emphasisFont: 'Inter', sans-serif;
$cardTitleFont: 'Inter', sans-serif;
$descriptionFont: 'Montserrat', sans-serif;