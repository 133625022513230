.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.image-container img {
    width: 40%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 40px;

    @media (max-width: 768px) {
        width: 90%;
      }
  }