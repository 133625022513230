@import '../../globalSassVariables.scss';

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: $secondaryBackground;
    width: 50%;
  }

.card-container a {
    margin-top: 20px;
    display: inline-block;
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
  }

.card-container path {
    color: $textColor;
  }