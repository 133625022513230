.progress-bar {
    position: relative;
    height: 30px;
    width: 80%;
    background-color: #838383;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px;

    @media (max-width: 768px) {
        width: 100%;
      }
  }

.progress-bar .fill {
    background-color: #5cb4e7;
    height: 100%;
    transition: width 0.2s ease-in;
  }

.progress-bar .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;

    @media (max-width: 768px) {
        font-size: 10px;
      }
  }