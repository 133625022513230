.discord-mention {
	color: #e3e7f8;
	background-color: hsla(235, 85.6%, 64.7%, 0.3);
	font-weight: 500;
	padding: 0px 2px 4px;
	border-radius: 3px;
	unicode-bidi: -moz-plaintext;
	unicode-bidi: plaintext;
	-webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
	transition: background-color 0.2s ease-out, color 0.2s ease-out;
	cursor: pointer;
  font-family: Whitney, 'Source Sans Pro', ui-sans-serif, system-ui, -apple-system, 'system-ui', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.discord-mention:hover {
	color: #fff;
	background-color: hsl(235, 85.6%, 64.7%);
}