@import url('https://fonts.googleapis.com/css2?family=REM:wght@700&display=swap');
@import './globalSassVariables.scss';

* {
    scroll-behavior: smooth;
  }

body {
    margin: 0;
    font-family: 'REM', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $primaryBackground;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

.title {
    color: $textColor;
    font-family: $emphasisFont;
    font-size: 60px;
    text-shadow: 3px 3px 10px rgba($textColor, 0.5);
    margin-bottom: 0;

    @media (max-width: 768px) {
      font-size: 40px;
      max-width: 90%;
    }
  }

.description {
    color: $textGray;
    font-family: $descriptionFont;
    font-size: 20px;

    @media (max-width: 768px) {
        max-width: 90%;
        font-size: 12px;
      }
  }